import React, { useState, useEffect } from 'react';
import request from 'request-promise';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { addDays, subDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import SECRETS from './secrets';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 600px;
  border-radius: 5px;
  padding: 50px 0;
`;

const Row = styled.div`
  height: 25px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const A = styled.a``;

const DateDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  input {
    border: 1px solid #333;
    border-radius: 5px;
    height: 30px;
    text-align: center;
  }
`;

const button = {
  marginBottom: '55px',
  backgroundColor: 'green',
  color: 'white',
  textDecoration: 'none',
  padding: '13px',
  borderRadius: '5px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const buttonDisabled = {
  marginBottom: '55px',
  backgroundColor: '#aaa',
  color: 'black',
  textDecoration: 'none',
  padding: '13px',
  borderRadius: '5px',
  fontWeight: 'bold',
};

const getUnidades = (token, setUnidades) => {
  request({
    method: 'GET',
    uri: `${SECRETS.serverUrl}/unidades`,
    json: true,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then((resp) => {
      setUnidades(resp);
    })
    .catch(err => console.error(err));
};


const MainScreen = ({ token }) => {
  const [ unidades, setUnidades ] = useState([]);
  const [ date, setDate ] = useState(subDays(new Date(), 1));
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    getUnidades(token, setUnidades);
  }, [token,]);

  useEffect(() => {
    registerLocale('es', es);
  }, []);

  const descargar = async() => {
    const URL = `${SECRETS.serverUrl}/reportes/morada2/all/${format(date, 'yyyy-MM-dd')}/${token}/rutas-${format(date, 'yyyy-MM-dd')}.xlsx`;
    setLoading(true);
    const response = await fetch(URL, { method: 'get', headers: { 'Content-Type': 'application/json' }})
      .catch(() => false);
    if (response) {
      const blob = await response.blob();
      const downloadurl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadurl;
      link.setAttribute('download', `rutas-${format(date, 'yyyy-MM-dd')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    setLoading(false);
  };

  return (
    <Container>
      {!token && <Redirect to="/" />}
      {/* <a href="#/checadas">Ir a Reporte de Checadas</a> */}
      <DateDiv>
        <span>Selecciona la fecha a consultar</span>
        <DatePicker
          selected={date}
          onChange={setDate}
          minDate={new Date('Oct 25, 2019')}
          maxDate={subDays(new Date(), 1)}
          placeholderText="Selecciona la fecha a consultar"
          locale="es"
        />
      </DateDiv>
      { !loading && (
        <div
          style={button}
          onClick={descargar}
        >
          Descargar todas las rutas del día
        </div>
      )}
      { loading && (
        <div
          style={buttonDisabled}
        >
          Generando Reporte...
        </div>
      )}
      <div>O descargar rutas individualmente:</div>
    {unidades.map(u => (
      <Row key={u.idUnidad}>
        <A
          href={`${SECRETS.serverUrl}/reportes/morada2/${u.idUnidad}/${format(date, 'yyyy-MM-dd')}/${token}/${u.unidad}/${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
          download={`${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
          title={`${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
          target="_blank"
        >
          {`${u.unidad}-${format(date, 'yyyy-MM-dd')}.xlsx`}
        </A>
      </Row>
    ))}
    </Container>
  );
};

export default MainScreen;
